<template>
	<div class="px-4 py-5 sm:p-6">
		<form class="bg-gray-50 p-4 rounded-lg shadow-md" @submit.prevent>
			<div>
				<div>
					<div>
						<h3 class="text-lg leading-6 font-medium text-gray-900">
							Create Account
						</h3>
						<p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
							Choose Account Name.
						</p>
						<div class="text-red-500 text-xs italic">
							<p v-if="errors">
								The form has errors, please check errors and resubmit
							</p>
							<p v-else-if="formTouched && uiState === 'submit clicked'">
								The form is empty, we could do with a valid Account Name
							</p>
							<!-- <p v-else-if="uiState === 'form submitted'" class="success">Account creation was submitted!</p> -->
						</div>
					</div>
					<div class="mt-6 sm:mt-5">
						<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
							<label for="accountName" class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
								Account Name
							</label>
							<div class="mt-1 sm:mt-0 sm:col-span-2">
								<div class="max-w-lg flex rounded-md shadow-sm">
									<span
										class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
									>
										app.storism.io/
									</span>
									<input
										id="accountName"
										v-model.lazy="formResponses.accountName"
										class="flex-1 form-input block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
									/>
								</div>
								<p class="m-3 text-xs text-gray-400">
									<em>{{ urlExample }}</em>
								</p>
								<p v-if="errors" class="text-red-500 text-xs italic">
									<span v-if="!$v.formResponses.accountName.required">Account Name is required</span>
									<span v-else-if="!$v.formResponses.accountName.isAvailable">Account Name is not available</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="mt-8 border-t border-gray-200 pt-5">
				<div class="flex justify-end">
					<span class="inline-flex rounded-md shadow-sm">
						<router-link type="button" class="btn btn-cancel" :to="{ name: 'account' }">Cancel</router-link>
					</span>
					<span class="ml-3 inline-flex rounded-md shadow-sm">
						<button type="submit" class="btn btn-indigo" @click="handleGo">
							<!-- <font-awesome-icon class="text-white text-3xl" :icon="['fad', 'cog']" spin /> -->
							<span v-if="isLoading">Loading</span>
							<span v-else>Register</span>
							<!-- <font-awesome-icon :icon="['fad', 'user-secret']" /> -->
						</button>
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { ACCOUNT } from '@/store/actions.type'
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import firebase from '@/helpers/firebaseInit.js'
export default {
	name: 'AccountCreate',
	data() {
		return {
			formResponses: {
				accountName: ''
			},
			checked: '',
			available: false,
			uiState: 'submit not clicked',
			errors: false,
			empty: true,
			formTouched: false
		}
	},
	metaInfo() {
		return {
			title: 'Create Account'
		}
	},
	computed: {
		urlExample() {
			return 'https://app.storism.io/' + this.shortAccountName
		},
		shortAccountName() {
			return this.shortName(this.$v.formResponses.accountName.$model)
		},
		validName() {
			return this.available && this.checked === this.shortAccountName
		},
		...mapState('ui', ['isLoading'])
	},
	validations: {
		formResponses: {
			accountName: {
				required,
				isAvailable() {
					return this.available
				}
			}
		}
	},
	methods: {
		async handleGo() {
			this.$v.$touch()
			await this.handleCheck()
			this.handleSubmit()
		},
		async handleCheck() {
			if (!this.shortAccountName) {
				return
			}

			let result = await firebase.accountCollection
				.where('_name', '==', this.shortAccountName)
				.get()
				.then((snapshot) => {
					return snapshot.empty
				})
			this.checked = this.shortAccountName
			this.available = result
		},
		async handleSubmit() {
			this.formTouched = !this.$v.formResponses.$anyDirty
			this.errors = this.$v.formResponses.$anyError
			this.uiState = 'submit clicked'
			if (this.errors === false && this.formTouched === false) {
				//this is where you send the responses
				this.uiState = 'form submitted'
				this.addValidAccount()
			}
		},
		async addValidAccount() {
			this.$store.commit('ui/loading', true)
			let account = {
				name: this.$v.formResponses.accountName.$model,
				_name: this.shortAccountName
			}

			await this.$store.dispatch(`accounts/${ACCOUNT.ADD}`, account)

			await firebase.userCollection.doc(this.$store.state.auth.user.id).set({ accounts: { [account._name]: account } }, { merge: true })

			await this.$store.dispatch('auth/refreshUser', this.$store.state.auth.user.id)

			var createAccount = firebase.functions.httpsCallable('account-create')

			const isProd = process.env.NODE_ENV === 'production'
			let stripeData = {
				stripeId: isProd ? this.$store.state.auth.user.stripe_id : this.$store.state.auth.user.stripe_id_test,
				plan: isProd ? 'plan_GtHFu6NSBl28kb' : 'plan_Go3rh747k6N0uT'
			}

			let config = {
				account: this.shortAccountName,
				userId: this.$store.state.auth.user.id,
				email: this.$store.state.auth.user.email,
				stripeId: stripeData.stripeId,
				plan: stripeData.plan
			}

			createAccount(config)
				.then(() => {
					this.$sendInBlue.track(
						'account_created',
						{ email: this.$store.state.auth.email },
						{ id: this.shortAccountName, data: { name: account.name } }
					)
					this.$analytics.logEvent('create_account', { name: this.shortAccountName })
					this.$store.commit('ui/loading', false)
					this.$router.push({ name: 'account' })
				})
				.catch(this.$store.commit('ui/loading', false))
		}
	}
}
</script>
